import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Container, Row } from "react-bootstrap"
import footerStyles from "./footer.module.scss"

const Footer = () => {
  let year = new Date().getFullYear()

  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          author
        }
      }
    }
  `)

  return (
    <footer>
      <Container className="d-flex justify-content-end" fixed="bottom">
        <Row>
          <p className={footerStyles.footer}>
            Created by {data.site.siteMetadata.author} &copy; {year}
          </p>
        </Row>
      </Container>
    </footer>
  )
}

export default Footer
