import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import { Nav, Image, Navbar, Container } from "react-bootstrap"
import headerStyles from "./header.module.scss"
import logo from "../img/tbr.png"

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Navbar
        className={headerStyles.header}
        collapseOnSelect
        expand="sm"
        variant="dark"
      >
        <Container>
          <Navbar.Brand>
            <Nav.Link as={Link} className={headerStyles.title} to="/">
              <Image
                as={Image}
                src={logo}
                className={headerStyles.logo}
                alt="Tanner B. Roof's Portfolio"
              />
            </Nav.Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav defaultActiveKey="/blog" className="ml-auto">
              <Nav.Link
                activeClassName="active"
                as={Link}
                className={headerStyles.navItem}
                to="/blog"
              >
                Blog
              </Nav.Link>
              <Nav.Link
                as={Link}
                activeClassName="active"
                className={headerStyles.navItem}
                to="/about"
              >
                About
              </Nav.Link>
              <Nav.Link
                as={Link}
                activeClassName="active"
                className={headerStyles.navItem}
                to="/contact"
              >
                Contact
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  )
}

export default Header
